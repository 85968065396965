import http from '@/config/axios'
import Vue from "vue";

export default {
    state: {
        products: [],
        productsNoFilter: [],
        product: [],
        attributes: [],
        categories: [],
        filterCategories: {
            mCategory: [],
            sCategory: [],
        },
        totalCount: 0,
        ozonAttributeSelectedValues: []
    },
    getters: {
        productList: state => state.products,
        productListNoFilter(state) {
            return state.productsNoFilter;
        },
        product(state) {
            return state.product;
        },
        ozonAttributes: state => state.attributes,
        productCount(state) {
            return state.totalCount;
        },
        productCategories(state) {
            return state.categories;
        },
        filterCategories(state) {
            return state.filterCategories;
        },
        ozonAttributeSelectedValues: state => state.ozonAttributeSelectedValues,
    },
    actions: {
        async getProductList(ctx, filter) {
            await http.get('/product/list?limit=' + filter.limit
                + '&title=' + filter.search.title
                + '&barcode=' + filter.search.barcode
                + '&priceFrom=' + filter.search.priceFrom
                + '&priceTo=' + filter.search.priceTo
                + '&weightFrom=' + filter.search.weightFrom
                + '&weightTo=' + filter.search.weightTo
                + '&mCategory=' + this.getters.filterCategories.mCategory
                + '&sCategory=' + this.getters.filterCategories.sCategory
                + '&isCategory=' + filter.isCategory
                + '&isAvailable=' + filter.isAvailable
                + '&isImage=' + filter.isImage
                + '&isPriceLessYandex=' + filter.isPriceLessYandex
            ).then(async res => {
                ctx.commit('updateProductList', res.data.data)
            });
        },
        async getOzonAttributeSelectedValues(ctx, id) {
            const res = await http.get('/product/ozon-selected-attributes?productId=' + id);
            ctx.commit('updateOzonAttributeSelectedValues', res.data.data);
        },
        async getProductListNoFilter(ctx, region = 77) {
            await http.get('/product/list-no-filter?region=' + region).then(async res => {
                ctx.commit('updateProductListNoFilter', res.data.data.data)
            });
        },
        async getOzonAttributes(ctx, params) {
            await http.get('/ozon/attributes?categoryId=' + params.categoryId + '&typeId=' + params.typeId + '&productId=' + params.productId).then(async res => {
                ctx.commit('ozonAttributes', res.data.data);
            });
        },
        async getProduct(ctx, productId) {
            await http.get('/product/get?id=' + productId).then(async res => {
                ctx.commit('updateProduct', res.data.data);
            });
        },
        async saveProduct(ctx, item) {
            await http.post('/product/save', item).then(async res => {
                if (res.data.status) {
                    Vue.$toast.success('Товар успешно обновлён!');
                } else {
                    Vue.$toast.error(res.data.data);
                }
            });
        },
        async uploadImage(ctx, data) {
            await http.post('/product/image', data).then(async res => {
                if (res.data.status) {
                    window.location.reload();
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async saveOzonAttributesProduct(ctx, attributes) {
            await http.post('/product/save-attributes', attributes).then(async res => {
                if (res.data.status) {
                    Vue.$toast.success('Атрибуты успешно обновлены');
                } else {
                    Vue.$toast.error(res.data.data);
                }
            });
        },
        async editSortProduct(ctx, data) {
            await http.post('/product/edit-sort', data).then(async res => {
                if (res.data.result == 'ok') {
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async changeActiveProduct(ctx, id) {
            await http.get('/product/change-visible?id=' + id).then(async res => {
                if (res.data.result == 'ok') {
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async changeProperty(ctx, data) {
            await http.post('/product/change-property', data).then(async res => {
                if (res.data.result == 'ok') {
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async deleteImageProduct(ctx, data) {
            await http.post('/product/delete-image', data).then(async res => {
                if (res.data.result == 'ok') {
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async exportActionProduct() {
            return new Promise((resolve, reject) => {
                http.post('/product/export-action-product').then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
    },
    mutations: {
        updateProductList(state, data) {
            state.products = data.data.products;
            state.categories = data.data.categories;
            state.totalCount = data.data.totalCount;
        },
        updateProductListNoFilter(state, data) {
            state.productsNoFilter = data.products;
        },
        updateFilterCategories(state, filter) {
            state.filterCategories = filter;
        },
        updateProduct(state, product) {
            state.product = product;
        },
        ozonAttributes(state, attributes) {
            state.attributes = attributes;
        },
        updateOzonAttributeSelectedValues(state, data) {
            state.ozonAttributeSelectedValues = data
        },
    },
}