<template>
  <div class="topbar">
    <div class="d-flex align-items-center justify-content-between ">
      <div class="toggle-menu pointer" @click="toggleSideBar">
        <a class="d-flex align-items-center text-decoration-none fa-lg">
          <i class="fas fa-bars"></i>
        </a>
      </div>
      <div class="dropdown text-end d-flex">
        <span class="user-name">{{ this.$store.state.auth.user }}</span>
        <a href="#" class="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="https://github.com/mdo.png" alt="mdo" width="32" height="32" class="rounded-circle">
        </a>
        <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1">
          <li>
            <router-link to="/profile" class="dropdown-item pointer">Профиль</router-link>
          </li>
          <li><hr class="dropdown-divider"></li>
          <li>
            <a class="dropdown-item pointer" @click="logout">Выйти</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
.user-name{
  display: flex;
  align-items: center;
  margin-right: 10px;
}
</style>

<script>
export default {
  name: 'TopBar',
  data: function () {
    return {
    }
  },
  methods: {
    toggleSideBar: function(){
      let el = document.querySelector('.sidebar');
      if(el.classList.contains('open')){
        el.classList.remove('open');
      }else{
        el.classList.add('open');
      }
    },
    logout: function(){
      this.$store.dispatch("logout");
    },
  },
}
</script>

<style lang="scss" scoped>
.topbar {
  padding: 10px;
  box-shadow: 0 2px 10px 0 rgba(182, 182, 182, 0.25);
  background: white;
}

.topbar .toggle-menu i {
  color: #A4A6B3;
}

</style>