import Vue from 'vue';
import App from './App.vue';
import * as Bootstrap from 'bootstrap';
import router from './router';
import store from './store';
import Lodash from 'lodash';
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip';
import LiquorTree from 'liquor-tree';
import VueQuillEditor from 'vue-quill-editor'
import VueToast from 'vue-toast-notification';
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use(VueQuillEditor);
Vue.use(LiquorTree);
Vue.use(VueCustomTooltip);
Vue.use(Bootstrap);
Vue.use(Lodash);
Vue.use(VueToast, {'position': 'top-right'});
Vue.use(CKEditor);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
