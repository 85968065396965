<template>
  <div class="main-body">
    <TopBar/>
    <router-view/>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar.vue';

export default {
  name: 'AppBody',
  components: { TopBar }
}
</script>

<style lang="scss" scoped>
.main-body {
  width: 100%;
}
</style>