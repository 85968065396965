import http from '@/config/axios'

export default {
    actions: {
        async getSlotsList(ctx) {
            const res = await http.get('/stores/get-slots');
            ctx.commit('updateSlotsInfo', res.data.data.slots);
        },
    },
    mutations: {
        updateSlotsInfo(state, data){
            state.slots = data;
        }
    },
    state: {
        slots: []
    },
    getters: {
        slotsInfo: state => state.slots,
    }
}