import http from '@/config/axios'

export default {
    namespaced: true,
    state: {
        todayInfo: [],
        productInfo: [],
        ordersChart: [],
        weekChart: [],
        aggregatorOrdersInfo: [],
    },
    getters: {
        todayInfo: state => state.todayInfo,
        productInfo: state => state.productInfo,
        ordersChart: state => state.ordersChart,
        weekChart: state => state.weekChart,
        aggregatorOrdersInfo: state => state.aggregatorOrdersInfo
    },
    actions: {
        async getTodayInfo(ctx) {
            const res = await http.get('/index/info-today');
            ctx.commit('SET_TODAY_INFO', res.data.data.todayStatistics);
        },
        async getProductInfo(ctx) {
            const res = await http.get('/index/product-info');
            ctx.commit('SET_PRODUCT_INFO', res.data.data.productInfo);
        },
        async getOrdersChart(ctx, dateRange) {
            const res = await http.get(`/index/orders-chart?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`);
            ctx.commit('SET_ORDERS_CHART', res.data.data.chart);
        },
        async getWeekChart(ctx) {
            const res = await http.get('/index/week-chart');
            ctx.commit('SET_WEEK_CHART', res.data.data);
        },
        async getAggregatorOrders(ctx) {
            const res = await http.get('/index/aggregator-orders');
            ctx.commit('SET_AGGREGATOR_ORDERS', res.data.data);
        }
    },
    mutations: {
        SET_TODAY_INFO(state, value) {
            state.todayInfo = value
        },
        SET_PRODUCT_INFO(state, value) {
            state.productInfo = value
        },
        SET_ORDERS_CHART(state, value) {
            state.ordersChart = value
        },
        SET_WEEK_CHART(state, value) {
            state.weekChart = value
        },
        SET_AGGREGATOR_ORDERS(state, value) {
            state.aggregatorOrdersInfo = value
        },
    },
}