<template>
  <component :is="layout">
    <router-view/>
    <notifications/>
  </component>
</template>

<script>
import EmptyLayout from '@/components/layouts/EmptyLayout';
import MainLayout from '@/components/layouts/MainLayout';
import {mapGetters} from 'vuex';

export default {
  name: 'App',
  components: {
    EmptyLayout, MainLayout
  },
  computed: {
    ...mapGetters(['profileGet']),
    layout () {
      return this.$route.meta.layout
    },
  },
  async mounted () {
    this.getProfile();
  },
  methods: {
    getProfile: function () {
      if (this.$store.getters.isLoggedIn) {
        this.$store.dispatch('getProfile', this.$store.getters.user_id);
      }
    },
  }
}
</script>

<style lang="scss">
#app {

}
</style>
