import http from '@/config/axios'
import Vue from "vue";

export default {
    actions: {
        async getUserList(ctx, filter) {
            const res = await http.get('/user/list?limit=' + filter.visible
                + '&search=' + filter.search
                + '&accept=' + filter.acceptOrder
                + '&dateBefore=' + filter.orderDateBefore
                + '&dateAfter=' + filter.orderDateAfter
                + '&avgBefore=' + filter.avgCheckBefore
                + '&avgAfter=' + filter.avgCheckAfter
                + '&ordMrThn=' + filter.ordersMoreThen
                + '&idProd=' + filter.productIDFromChildren
            );
            ctx.commit('updateUserList', res.data.data);
        },
        async getUserQuestion(ctx, filter) {
            const res = await http.get('/user/list-question?limit=' + filter.visible);
            ctx.commit('updateUserListQuestion', res.data.data);
        },
        async getUserListNoFilter(ctx, value) {
            const res = await http.get(`/user/list-no-filter?full_name=${value}`);
            ctx.commit('updateUserListNoFilter', res.data.data.users);
        },
        async getUser(ctx, id) {
            const res = await http.get('/user/get?id=' + id);
            ctx.commit('updateUser', res.data.data.user);
        },
        async addScore(ctx, data) {
            const res = await http.post('/user/add-score', data);
            if (res.data.result == 'ok') {
                Vue.$toast.success(res.data.message);
            } else {
                Vue.$toast.error(res.data.message);
            }
        },
        async sendWhatsApp(ctx, data) {
            const res = await http.post('/whats-app/send', data);
            if (res.data.result == 'ok') {
                Vue.$toast.success(res.data.message);
            } else {
                Vue.$toast.error(res.data.message);
            }
        },
        async removeScore(ctx, data) {
            const res = await http.post('/user/remove-score', data);
            if (res.data.result == 'ok') {
                Vue.$toast.success(res.data.message);
            } else {
                Vue.$toast.error(res.data.message);
            }
        },
        async changeAdv(ctx, data) {
            const res = await http.post('/user/change-adv', data);
            if (res.data.result == 'ok') {
                Vue.$toast.success(res.data.message);
            } else {
                Vue.$toast.error(res.data.message);
            }
        },
        async createUser(ctx, data) {
            return new Promise((resolve, reject) => {
                http.post("/user/create-user", data).then(res => {
                    resolve(res);
                }, error => {
                    reject(error);
                })
            })
        },
        async editUser(ctx, data) {
            return new Promise((resolve, reject) => {
                http.post("/user/edit-user", data).then(res => {
                    resolve(res);
                }, error => {
                    reject(error);
                })
            })
        },
        async getUsersExport(ctx, data) { // Экспорт xlsx файла из FrontEnd, с id пользователей из списка фильтров
            return new Promise((resolve, reject) => {
                http.post('/user/export', data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        async getProductsBySearch(ctx, data) { // Поиск товаров с /list-users для последующей выгрузки списка пользователей, купивших конкретный товар
            return new Promise((resolve, reject) => {
                http.post('/user/get-products-by-search', data).then(res => {
                    resolve(res);
                    if (res.data.result === 'ok') {
                        Vue.$toast.success(res.data.message);
                    }else{
                        Vue.$toast.error(res.data.message);
                    }
                }).catch(e => {
                    reject(e);
                });
            });
        },
    },
    mutations: {
        updateUserList(state, users) {
            state.users = users;
        },
        updateUserListQuestion(state, questions) {
            state.questions = questions;
        },
        updateUserListNoFilter(state, users) {
            state.usersNoFilter = users;
        },
        updateUser(state, user) {
            state.user = user;
        },
        updateFilterProducts(state, filter){
            state.filterProducts = filter;
        },
    },
    state: {
        users: [],
        questions: [],
        usersNoFilter: [],
        user: [],
        filterProducts: '',
    },
    getters: {
        userList(state) {
            return state.users;
        },
        listQuestions: state => state.questions,
        usersListNoFilter: state => state.usersNoFilter,
        userGet(state) {
            return state.user;
        }
    }
}
