<template>
  <div class="main-layout">
    <Sidebar/>
    <AppBody/>
    <LoadingSpinner :active="isLoading"/>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import AppBody from '@/components/body.vue'
import LoadingSpinner from '@/components/feature/LoadingSpinner.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MainLayout',
  components: { Sidebar, AppBody, LoadingSpinner },
  computed: {
    ...mapGetters(['isLoading']),
  }
}
</script>

<style lang="scss" scoped>
.main-layout {
  display: flex;
}
</style>