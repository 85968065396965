import http from '@/config/axios'
import Vue from "vue";
import store from "../../index";

export default {
    actions: {
        async getCallList(ctx, data) {
            const res = await http.get('/call/list?limit=' + data.limit
                            + '&page=' + data.currentPage
                            + '&search=' + data.search
                            + '&ordercountbefore=' + data.orderCountBefore
                            + '&ordercountafter=' + data.orderCountAfter
                            + '&avgcheckbefore=' + data.avgCheckBefore
                            + '&avgcheckafter=' + data.avgCheckAfter
                            + '&lastorderbefore=' + data.lastOrderBefore
                            + '&lastorderafter=' + data.lastOrderAfter
                            + '&called=' + data.called);
            ctx.commit('updateCallList', res.data.data);
        },
        async getCallStats(ctx, data){
            const res = await http.get('/call/list-stats?limit=' + data.limit);
            ctx.commit('updateCallListStats', res.data.data.manager);
        },
        async getScreenCall(ctx){
            const res = await http.get('/call/screen-call');
            ctx.commit('updateScreenCall', res.data.data);
        },
        async getScreenAnalytics(ctx, data){
            const res = await http.post('/call/screen-analytics', data);
            if(res.data.result == 'ok'){
                ctx.commit('updateScreenAnalytics', res.data.data);
            }else{
                Vue.$toast.error(res.data.message);
            }
        },
        async startCall(ctx, id){
            const res = await http.get('/call/get?id=' + id);
            ctx.commit('updateCall', res.data.data);
        },
        async checkVisitCall(ctx, id){
            const res = await http.get('/call/check-visit?id=' + id);
            if(res.data.result == 'ok' && res.data.data == true){
                await store.dispatch('openModal', 'viewCall');
            }else{
                Vue.$toast.error(res.data.message);
            }
        },
        async createCall(ctx, data){
            const res = await http.post('/call/create', data);
            if(res.data.result == 'ok'){
                Vue.$toast.success(res.data.message);
            }else{
                Vue.$toast.error(res.data.message);
            }
        },
        async callClient(ctx, id){
            const res = await http.post('/call/call-client', {'id': id});
            if(res.data.result == 'ok'){
                Vue.$toast.success(res.data.message);
            }else{
                Vue.$toast.error(res.data.message);
            }
        },
        async recall(ctx, id){
            const res = await http.get('/call/recall-tomorrow?id=' + id);
            if(res.data.result == 'ok'){
                Vue.$toast.success(res.data.message);
            }else{
                Vue.$toast.error(res.data.message);
            }
        }
    },
    mutations: {
        updateCallList(state, data){
            state.list = data.users;
            state.totalCount = data.totalCount;
            state.callToday = data.callToday;
            state.callMonth = data.callMonth;
            state.callSuccessToday = data.callSuccessToday;
            state.callSuccessMonth = data.callSuccessMonth;
            state.callFailedToday = data.callFailedToday;
            state.callFailedMonth = data.callFailedMonth;
            state.awardToday = data.awardToday;
            state.awardMonth = data.awardMonth;
            state.amountOrderToday = data.amountOrderToday;
            state.amountOrderMonth = data.amountOrderMonth;
        },
        updateCall(state, data){
            state.call = data.user;
            state.orders = data.orders;
        },
        updateCallListStats(state, data){
            state.listStats = data;
        },
        updateScreenCall(state, data){
            state.screen = data.manager;
            state.screenTotal = data.total;
        },
        updateScreenAnalytics(state, data){
            state.screenAnalytics = data.screen;
        }
    },
    state: {
        list: [],
        callToday: 0,
        callMonth: 0,
        callSuccessToday: 0,
        callSuccessMonth: 0,
        callFailedToday: 0,
        callFailedMonth: 0,
        awardToday: [],
        awardMonth: [],
        amountOrderToday: 0,
        amountOrderMonth: 0,
        totalCount: 0,
        listStats: [],
        call: [],
        orders: [],
        screen: [],
        screenTotal: [],
        screenAnalytics: [],
    },
    getters: {
        callList(state){
            return state.list;
        },
        totalCountList: state => state.totalCount,
        screenCall: state => state.screen,
        screenAnalytics: state => state.screenAnalytics,
        screenCallTotal: state => state.screenTotal,
        callToday: state => state.callToday,
        callMonth: state => state.callMonth,
        callSuccessToday: state => state.callSuccessToday,
        callSuccessMonth: state => state.callSuccessMonth,
        callFailedToday: state => state.callFailedToday,
        callFailedMonth: state => state.callFailedMonth,
        awardToday: state => state.awardToday,
        awardMonth: state => state.awardMonth,
        amountOrderToday: state => state.amountOrderToday,
        amountOrderMonth: state => state.amountOrderMonth,
        getCall(state){
            return state.call;
        },
        getOrders(state){
            return state.orders;
        },
        callListStats(state){
            return state.listStats;
        }
    }
}