import http from '@/config/axios'
import Vue from "vue";

export default {
    actions: {
        async sendSms(ctx, data){
            const res = await http.post('/sms/send-sms', data);
            if(res.data.result == 'ok'){
                Vue.$toast.success(res.data.message);
            }else{
                Vue.$toast.error(res.data.message);
            }
        }
    },
    mutations: {

    },
    state: {

    },
    getters: {

    }
}