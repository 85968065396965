import http from '@/config/axios'
import Vue from "vue";
import router from '@/router';

export default {
    actions: {
        async getBannerList(ctx, data) {
            const res = await http.get('/banner/list?limit=' + data);
            ctx.commit('updateBannerList', res.data.data.banners);
        },
        async getBanner(ctx, id){
            const res = await http.get('/banner/get?id=' + id);
            ctx.commit('updateBanner', res.data.data.banner);
        },
        async getBannerPlace(ctx) {
            const res = await http.get('/banner/place-list');
            ctx.commit('updatePlaceList', res.data.data);
        },
        async createBanner(ctx, data){
            let formData = new FormData();
            formData.append('name', data.name);
            formData.append('page', data.page);
            formData.append('url', data.url);
            formData.append('image_web', data.image_web);
            formData.append('image_mob', data.image_mob);
            formData.append('region', data.region);
            formData.append('product', data.product.id);
            formData.append('category', data.category.id);
            formData.append('sort', data.sort);
            await http.post('/banner/create-banner', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(async res => {
                if(res.data.result == 'ok'){
                    router.go(-1);
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async editBanner(ctx, data){
            let formData = new FormData();
            formData.append('id', data.id);
            formData.append('name', data.name);
            formData.append('page', data.page);
            formData.append('url', data.url);
            formData.append('region', data.region);
            formData.append('product', data.product.id);
            formData.append('category', data.category.id);
            formData.append('sort', data.sort);
            await http.post('/banner/edit-banner', formData).then(async res => {
                if(res.data.result == 'ok'){
                    router.go(-1);
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async editSortBanner(ctx, data){
            await http.post('/banner/edit-sort', data).then(async res => {
                if(res.data.result == 'ok'){
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async deleteBanner(ctx, data){
            await http.get('/banner/delete-banner?id=' + data).then(async res => {
                if(res.data.result == 'ok'){
                    ctx.commit('updateBannerList', res.data.data.banners);
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        }
    },
    mutations: {
        updateBannerList(state, banners){
            state.banners = banners;
        },
        updateBanner(state, banner){
            state.banner = banner;
        },
        updatePlaceList(state, data){
            state.places = data.places;
        }
    },
    state: {
        banners: [],
        banner: [],
        places: [],
    },
    getters: {
        bannerList(state){
            return state.banners;
        },
        bannerGet(state){
            return state.banner;
        },
        placeList(state){
            return state.places;
        },
    }
}