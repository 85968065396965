import http from '@/config/axios'

export default {
    actions: {
        async getMBCategory(ctx) {
            const res = await http.get('/multi-bonus/get-info-tree');
            ctx.commit('updateInfoTree', res.data.data);
        },
        async getMBParentCategory(ctx) {
            const res = await http.get('/multi-bonus/get-list-parent-category');
            ctx.commit('updateListParent', res.data.data);
        },
        async getMBProducts(ctx, filter) {
            const res = await http.get('/multi-bonus/get-list-products?limit=' + filter.limit);
            ctx.commit('updateListProducts', res.data.data);
        },
        async createMBCategory(ctx, data) {
            return new Promise((resolve, reject) => {
                http.post("/multi-bonus/create-category", data).then(res => {
                    resolve(res);
                }, error => {
                    reject(error);
                })
            })
        },
        async saveToMultiBonus(ctx, data) {
            return new Promise((resolve, reject) => {
                http.post("/multi-bonus/save-to-multi-bonus", data).then(res => {
                    resolve(res);
                }, error => {
                    reject(error);
                })
            })
        }
    },
    mutations: {
        updateInfoTree(state, data) {
            state.infoTree = data.categories;
        },
        updateListParent(state, data) {
            state.parentCategory = data.categories;
        },
        updateListProducts(state, data) {
            state.listProducts = data.data.products;
        },
    },
    state: {
        infoTree: [],
        parentCategory: [],
        listProducts: [],
    },
    getters: {
        treeList: state => state.infoTree,
        parentList: state => state.parentCategory,
        listProductsInfo: state => state.listProducts
    }
}