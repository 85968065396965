import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
import EmptyLayout from '@/components/layouts/EmptyLayout.vue';
import MainLayout from '@/components/layouts/MainLayout.vue';

Vue.use(Router)

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/auth',
            name: 'login',
            component: () => import('@/views/auth/login.vue'),
            meta: {
                layout: EmptyLayout,
                requiresAuth: false,
            }
        },
        {
            path: '/',
            name: 'index',
            component: () => import('@/views/index.vue'),
            meta: {
                layout: MainLayout,
                requiresAuth: true
            }
        },
        {
            path: '/list-users',
            name: 'list-users',
            component: () => import('@/views/users/list-users.vue'),
            meta: {
                layout: MainLayout,
                requiresAuth: true
            }
        },
        {
            path: '/view-user/:id',
            name: 'view-user',
            component: () => import('@/views/users/view-user.vue'),
            props: true,
            meta: {
                layout: MainLayout,
                requiresAuth: true
            }
        },
        {
            path: '/list-question',
            name: 'list-question',
            component: () => import('@/views/users/list-question.vue'),
            meta: {
                layout: MainLayout,
                requiresAuth: true
            }
        },
        {
            path: '/list-products',
            name: 'list-products',
            component: () => import('@/views/products/list-product.vue'),
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/view-product/:id',
            name: 'view-product',
            component: () => import('@/views/products/view-product.vue'),
            props: true,
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/list-orders',
            name: 'list-orders',
            component: () => import('@/views/orders/list-order.vue'),
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/create-order',
            name: 'create-order',
            component: () => import('@/views/orders/create-order.vue'),
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/view-order/:id',
            name: 'view-order',
            component: () => import('@/views/orders/view-order.vue'),
            props: true,
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/list-banners',
            name: 'list-banners',
            component: () => import('@/views/banners/list-banners.vue'),
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/create-banner',
            name: 'create-banner',
            component: () => import('@/views/banners/create-banner.vue'),
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/edit-banner/:id',
            name: 'edit-banner',
            component: () => import('@/views/banners/edit-banner.vue'),
            props: true,
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/list-category',
            name: 'list-category',
            component: () => import('@/views/category/list-category.vue'),
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/view-category/:id',
            name: 'view-category',
            component: () => import('@/views/category/view-category.vue'),
            props: true,
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/edit-category/:id',
            name: 'edit-category',
            component: () => import('@/views/category/edit-category.vue'),
            props: true,
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/edit-sub-category/:id',
            name: 'edit-sub-category',
            component: () => import('@/views/category/edit-sub-category.vue'),
            props: true,
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import('@/views/settings/settings.vue'),
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/promocodes',
            name: 'promocodes',
            component: () => import('@/views/promocodes/promocodes.vue'),
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/mailing/create',
            name: 'mailing-create',
            component: () => import('@/views/mailing/Create.vue'),
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import('@/views/profile/profile.vue'),
            meta: {
                layout: MainLayout,
                requiresAuth: true
            },
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (window.callScreenInterval) {
        clearInterval(window.callScreenInterval)
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/auth')
    } else {
        next()
    }
});

export default router
